@import "src/global-styles/colors";
@import "src/global-styles/typography";

.helpful-resource {
    width: 275px;
    margin: 2px 10px 20px 10px;
    overflow: hidden;
    border: 1px solid #d4d4d4;
    padding: 10px;

    .heading {
        color: #197a56;
        font-size: 14px;
        font-weight: 700;
        line-height: 15px;
        height: 32px;
    }

    .sub-heading {
        color: #323232;
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0;
        margin: 0;
        min-height: 75px;
    }

    .helpful-resources-links {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .learn-button {
            display: flex;
            cursor: pointer;
            padding: 11px 5px;
            border: 1px solid #008000;
            justify-content: center;
            align-items: center;
            margin: 5px 0;
            font-style: normal;
            color: #197a56;
            font-size: 12px;
            text-transform: uppercase;
            width: auto;
            min-width: 110px
        }

        a.learn-button:hover {
            border: 2.5px solid #0e3e1b;
            color: #0e3e1b !important
        }

        .request-now {
            margin: 5px 0;
            display: block;
            padding: 11px;
            border: 1px solid #197a56;
            margin-right: 5px;
            color: #fff;
            font-size: 12px;
            justify-content: flex-end;
            text-transform: uppercase;
            background-color: #197a56;
            min-width: 110px;
            width: 125px;
            text-align: center;
            cursor: pointer;
        }

        .request-now:hover {
            background-color: #2f4f4f;
        }
    }
}