@import "src/global-styles/colors";
@import "src/global-styles/typography";

.service {
    background-color: #f2f2f2;
    height: 468px;
    width: 283px;
    margin: 2px 5px;
    overflow: hidden;

    .heading {
        display: flex;
        margin-left: 10px;
        margin-bottom: 15px;
        position: relative;
        padding-top: 5px;

        img {
            height: 30px;
            width: 30px;
            margin: 5px;
        }

        img.tool-tip-icon {
            height: 12px;
            width: 13px;
        }

        .custom-tooltip {
            position: absolute;
            display: inline-block;
            right: 3px;
            top: 15px;
            z-index: 1000
        }

        .custom-tooltip-text {
            width: 240px;
            color: #fff;
            text-align: center;
            border-radius: 0;
            font-size: 14px;
            line-height: 21px;
        }

        .title {
            font-family: $sans-bold;
            font-size: 15px;
            line-height: 20px;
            color: #333;
            padding-top: 10px;
        }
    }



    .sub-services {
        height: 412px;
        overflow: auto;
        overflow-x: hidden;
        position: relative;

        .sub-service {
            margin-left: 10px;
            cursor: pointer;
            display: flex;
            margin-bottom: 11px;
            width: 260px;
            height: 50px;
            background-color: #fff;
            align-items: center;

            .sub-service-title {
                padding: 10px;
                font-size: 12px;
                line-height: 13px;
                width: 93%;
                color: #333333;
            }

            .arrow-down {
                height: 10px;
                width: 10px;
            }
        }

        .sub-service.highlighted {
            background-color: #DCF9E3;
            color: #0E3E1B;
            position: relative;

            .sub-service-title {
                padding-top: 12px;
            }

            img.ui.image.new-service-badge {
                position: absolute;
                top: 0px;
                left: -8px;
                width: 30px;
            }
        }

        .sub-service-expanded {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 10px;

            .sub-service-header {
                cursor: pointer;
                background-color: #197a56;
                color: #fff;
                box-shadow: 0 4px 4px #404040;
                display: flex;
                align-items: center;
                width: 276px;
                padding: 5px 18px;
                margin-left: 1px;
                height: 50px;
            }

            .sub-service-content {
                background: #f2f2f2;
                mix-blend-mode: normal;
                color: #000;
                width: 275px;
                box-shadow: 0px 4px 4px #404040;
                margin-left: 2px;
                padding: 15px 10px;

                .sub-service-description {
                    padding-bottom: 10px;

                    ul.cts-ul-bullets {
                        list-style: disc;
                        margin-left: 16px;

                        li {
                            list-style: disc;
                        }
                    }

                    a.category-link-url,
                    a.category-link-url:hover {
                        color: #197a56;
                        text-decoration: underline;
                    }

                }

                .sub-service-links {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .learn-button {
                        display: flex;
                        cursor: pointer;
                        padding: 11px 5px;
                        border: 1px solid #008000;
                        justify-content: center;
                        align-items: center;
                        margin: 5px 0;
                        font-style: normal;
                        color: #197a56;
                        font-size: 12px;
                        text-transform: uppercase;
                        width: auto;
                        min-width: 110px
                    }

                    a.learn-button:hover {
                        border: 2.5px solid #0e3e1b;
                        color: #0e3e1b !important
                    }

                    .request-now {
                        margin: 5px 0;
                        display: block;
                        padding: 11px;
                        border: 1px solid #197a56;
                        margin-right: 5px;
                        color: #fff;
                        font-size: 12px;
                        justify-content: flex-end;
                        text-transform: uppercase;
                        background-color: #197a56;
                        min-width: 110px;
                        width: 110px;
                        text-align: center;
                        cursor: pointer;
                    }

                    .request-now:hover {
                        background-color: #2f4f4f;
                    }
                }
            }
        }
    }
}

.mutliple-services {
    .service {
        height: 229px;
        margin-bottom: 10px;
    }

    .sub-services {
        position: absolute;
        overflow: visible;

        .sub-service-expanded {
            z-index: 1000;
            position: relative;

            .sub-service-content .sub-service-links a.request-now {
                width: 120px;
            }
        }
    }
}