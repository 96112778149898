@import "src/global-styles/colors";
@import "src/global-styles/typography";

.cts-table {
    width: 100%;
    font-family: $sans-reg;

    .table-header {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;

        .table-head-item {
            display: inline-block;
            font-family: $sans-reg;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            border: none;
            color: $bcg-gray;
            text-align: left;
            vertical-align: middle;
            padding: 0px 10px;

            span.sortable-heading,
            span.filterable-heading {
                cursor: pointer;
            }

            img.sort-icon,
            img.filter-icon {
                margin-left: 6px;
                vertical-align: middle;
                cursor: pointer;
                display: inline;
            }

            div.filter-container {
                position: absolute;
                width: 212px;
                left: -45px;
                top: 25px;
                background: $gray--light;
                box-shadow: -3px -1px 7px rgba(0, 0, 0, 0.25);
                padding: 4px 2px;
                max-height: 340px;
                z-index: 1;

                div.filter-list-container::-webkit-scrollbar {
                    width: 5px;
                    height: 10px;
                }

                div.filter-list-container {
                    position: relative;
                    width: 210px;
                    background: $gray--light;
                    max-height: 256px;
                    min-height: 100px;
                    overflow: auto;
                    overflow-x: hidden;

                    div.filter-item {
                        width: 210px;
                        height: 32px;
                        padding: 8px 16px;
                        background: $gray--light;

                        .ui.checkbox label::before,
                        .ui.checkbox label::after {
                            width: 16px;
                            height: 16px;
                            border: 1px solid $bcggreenfive;
                            border-radius: 0px;
                        }

                        .ui.checkbox label::after {
                            background: $bcggreenfive;
                            color: white;
                        }

                        .ui.checkbox label:hover::before {
                            border: 2px solid $bcggreenfive;
                        }

                        .ui.checkbox.filter-checkbox label {
                            font-family: $sans-reg;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            color: $black;
                        }
                    }
                }

                .filter-separator {
                    border: 1px solid #B1B1B1;
                    margin: 6px 10px 0 10px;
                }

                div.filter-action-item {
                    width: 210px;
                    height: 44px;
                    padding: 8px 16px;

                    .btn {
                        text-transform: uppercase;
                        margin: 0;
                        text-decoration: none;
                        font-weight: 400;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        box-sizing: border-box;
                        cursor: pointer;
                        display: inline-flex;
                        font-family: $sans-reg;
                        font-style: normal;
                        border-radius: 0px;
                    }

                    .primary.button {
                        width: 81px;
                        height: 30px;
                        background: $bcggreenfive;
                        color: white;
                        border: 0px;
                        font-size: 14px;
                        float: right;
                    }

                    .primary.button:hover {
                        background-color: $bcggreenfivehover;
                    }

                    .secondary.button {
                        padding: 5px 0 8px 0;
                        color: $bcggreenfive;
                        border: 0px;
                        font-size: 14px;
                    }

                    .secondary.button:hover {
                        color: $bcggreenfivehover;
                    }
                }
            }
        }
    }

    .scrollable-table {
        padding-right: 8px;
    }

    .scrollable-parent {
        height: 742px;
        overflow: auto;
        padding-right: 3px;


    }

    div.scrollable-parent::-webkit-scrollbar {
        width: 5px;
    }

    .table-body {
        .table-row:hover {
            box-shadow: 0px 4px 4px #00000033;
        }

        .table-row {
            display: flex;
            flex-direction: row;
            height: 140px;
            background: #FFFFFF;
            border: 1px solid #D4D4D4;
            margin-bottom: 10px;
            cursor: pointer;

            .table-row-item {
                display: inline-block;

                .row-item-heading {
                    color: $bcg-gray;
                    display: block;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    font-family: $sans-reg;
                }

                .heading {
                    height: 60px;
                }

                .box {
                    padding: 9px;
                    height: 100%;
                }

                span.contact-link {
                    text-transform: initial;
                }

                .contact-icon-parent {
                    a {
                        display: inline-block;
                    }


                    .slack-link {
                        vertical-align: text-bottom;
                        margin-left: 24px;
                    }
                }

                .text-value {
                    color: $bcg-gray;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    font-family: $sans-reg;
                    line-height: 15px;
                }

                .last-comment-text {
                    line-height: 20px;
                    color: $black;
                    font-weight: 400;
                }

                .text-value.grey-box {
                    width: 100%;
                    height: 10px;
                    background: #F2F2F2;
                    border-radius: 8px;
                    display: block;
                    margin-bottom: 8px;
                }

                .text-value.grey-box.half {
                    width: 40%;
                }

                a {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    text-decoration-line: underline;
                    text-transform: uppercase;
                    color: $bcggreenfive;
                }

                .status-box {
                    background: #F2F2F2;

                    span.status {
                        padding: 3px 6px;
                        width: auto;
                        height: auto;
                        color: $white;
                        display: block;
                        text-align: center;
                        box-sizing: border-box;
                        font-size: 12px;
                        line-height: 16px;
                        background: $bcggreenfive;
                        border: 1px solid $bcggreenfive;
                        border-radius: 8px;
                        font-weight: 700;
                        word-break: break-word;
                        max-height: 40px;
                        overflow: hidden;
                    }
                }

                .tooltip-wrapper {
                    position: relative;
                    display: inline-block;
                    word-break: break-word;
                }

                .tooltip-wrapper .tooltiptext {
                    visibility: hidden;
                    width: 350px;
                    background-color: black;
                    text-align: left;
                    border-radius: 0px;
                    position: fixed;
                    z-index: 1003;
                    font-family: $sans-reg;
                    font-style: normal;
                    font-size: 13px;
                    color: #F2F2F2;
                    padding: 8px 12px;
                    line-height: 16px;
                    font-weight: 400;
                }

                .tooltip-wrapper .tooltiptext::after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 168px;
                    margin-left: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: black transparent transparent transparent;
                }

                .tooltip-wrapper.bottom .tooltiptext::after {
                    top: auto;
                    bottom: 100%;
                    border-color: transparent transparent black transparent;
                }

                .tooltip-wrapper.left .tooltiptext::after {
                    left: 355px;
                    top: 50%;
                    border-color: transparent transparent transparent black;
                }
            }
        }
    }
}