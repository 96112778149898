@import "src/global-styles/colors";
@import "src/global-styles/typography";

.sn-dashboard {
    position: relative;
    min-height: 400px;

    .no-record-found {
        text-align: center;
        padding-top: 20px;

        img {
            display: inline-block;
        }

        p {
            font-family: $sans-reg;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: $bcg-gray;
        }
    }
}