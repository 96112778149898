@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.my-requests {
    .banner-parent {
        background: #535353;
        height: auto;
        display: flex;
        margin-bottom: 10px;
        margin-top: -5px;
        padding: 10px 16px;

        .banner-info-icon {
            img {
                width: 16px;
                height: 22px;
            }
        }

        .banner-text {
            color: $white;
            font-size: 12px;
            margin: 0px 8px;
            line-height: 18px;
        }

        .banner-close-icon {
            cursor: pointer;

            img {
                width: 16px;
                height: 22px;
            }
        }
    }
}