@import "src/global-styles/colors";
@import "src/global-styles/typography";

.errorWrapper.cts-error-page {
    margin-left: auto;
    margin-top: 50px;
    margin-bottom: 100px;
    text-align: center;

    .errorWrapper__heading {
        color: $gray--light;
        font-family: $sans-bold;
        font-size: 150px;
        letter-spacing: 10px;
        line-height: 168px;
        margin-bottom: 10px;

        .error-image {
            height: 168px;
        }
    }

    .errorWrapper__message {
        letter-spacing: 3.2px;
        margin-bottom: 15px;

        font-family: $sans-reg;
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 47px;
        text-align: center;

        color: $black;
    }

    .errorWrapper__helpText {
        letter-spacing: 1.33px;
        max-width: 60%;
        margin: 0 auto;

        font-family: $sans-reg;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        text-align: center;

        color: $black;
    }

    .links-text {
        letter-spacing: normal;
        font-size: 19px;
        max-width: 64%
    }

    .service-links-wrapper {
        margin: 0 auto;
        font-family: $sans-reg;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: #197a56
    }

    .service-links-wrapper a {
        color: #197a56;
        text-decoration: none
    }

    .margin-auto {
        margin: 0 auto;
    }

    .btn {
        padding: 0 24px;
        width: 100%;
        font-size: 14px;
        text-transform: uppercase;
        margin: 0;
        text-decoration: none;
        min-height: 40px;
        height: 40px;
        font-weight: 400;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-flex;
        font-family: $sans-reg;
        font-style: normal;
        border-radius: 0;
    }

    .primary.button {
        width: 154px;
        background: $bcggreenfive;
        color: white;
        border: 0px;
        font-size: 14px;
    }

    .primary.button:hover {
        background-color: #0E3E1B;
    }

    .secondary.button {
        margin-left: 20px;
        width: 170px;
        border: 1px solid $bcggreenfive;
        color: $bcggreenfive;
        background-color: transparent;
    }

    .secondary.button:hover {
        background-color: $gray--light;
        color: #575757;
        border: 2px solid $bcggreenfour;
    }

    .secondary.button:focus,
    .primary.button:focus {
        box-shadow: none;
    }
}