@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";
@import "src/mixins/mixins";

body,
html {
  height: auto;
}

div ::-webkit-scrollbar {
  width: 5px;
}

.layout {
  // min-width: 1240px;
  min-width: 1170px;
  margin-top: 50px;

  .displaynone {
    display: none !important;
  }

  .main {
    /* IE11+ don't support <main> */
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $gray--light;
    top: 0;

    .component-content {
      background-color: $gray--light;
    }

    .banner-bcg {
      height: 200px;
      background: url("/assets/images/Blue_Banner.png");
      width: 100%;
      background-repeat: round;
    }

    .cts-header {
      margin: auto;
      display: flex;
      flex-grow: 2;
      padding: 37px;
      position: relative;
      top: 47px;
      width: 1200px;
      padding-left: 20px;
    }

    .briefcase-icon.field-promoicon2 {
      vertical-align: super;
      margin-top: -41px;

      /* margin-left: -39px; */
      img {
        width: 57px;
        height: 118px;
      }
    }


    .case-desc {
      color: #a8f0b8;
      font-weight: lighter;
      font-family: $sans-reg;
      font-style: normal;
      font-size: 16px;
      margin-left: 13px;
    }

    strong.case-text {
      color: #fff;
      font-family: $sans-bold;
      font-style: normal;
      font-size: 22px;
      display: block;
    }

    #wrapper {
      margin: auto;
      position: relative;
      top: -54px;
      border-radius: 10px;
      background-color: #fff;
      width: 1200px;
      min-height: 500px;
      padding: 20px;

      .announcements-parent {
        background: #f2f2f2;
        padding: 10px;
        margin: 0;
        border: 1px solid #197a56;
        position: relative;
        border-radius: 8px;
        font-size: 12px;
        margin-bottom: 10px;
        font-weight: 400;
        color: $black;

        .announcement div,
        .outage div {
          display: inline;
        }

        img.ui.image {
          display: inline-block;
        }

        .announcement-text {
          word-break: break-word;
          line-height: 12px
        }

        .close-icon {
          position: absolute;
          top: 8px;
          right: 9px
        }

        .announcement,
        .outage {
          width: 98%
        }

        .outage-heading {
          color: #d82216
        }

        .announcement {
          padding-bottom: 0
        }

        .announcement-icon,
        .outage-icon {
          vertical-align: middle
        }

        .announcement-heading,
        .outage-heading {
          display: inline-block;
          padding: 0 5px
        }

        .close-icon img {
          cursor: pointer
        }
      }

      .mfu-container {
        margin-bottom: 10px;

        .mfu-heading {
          font-size: 20px;
          font-weight: 700;
          color: $black;
        }

        a.mfu-button {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          gap: 8px;
          height: 40px;
          background: #535353;
          border-radius: 8px;
          color: $white;
          display: inline-block;
          margin-right: 12px;
          margin-top: 10px;
          cursor: pointer;
          line-height: 25px;
        }

        a.mfu-button:hover {
          color: #fff !important
        }
      }


      .menu-tab {

        a.active.item {
          color: #197a56;
          z-index: 4;
          background: #fff;
          border-width: 4px 1px 0px 1px;
          border-style: solid;
          border-color: #197a56;
          border-bottom-color: #ffffff;
          position: relative;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          cursor: pointer;
          top: -1px;
        }

        a.item {
          top: -2px;
          padding: 10px 24px;
          background: #f2f2f2;
          border-width: 4px 1px 1px 1px;
          border-color: #f2f2f2;
          // border-bottom-color: #197a56;
          font-family: $sans-reg;
          margin-right: 3px;

          div.sr-count-container {
            width: 30px;
            height: 0;
            position: relative;

            span.sr-count {
              width: 20px;
              height: 19px;
              background: #f2f2f2;
              border: 1px solid #535353;
              border-radius: 10px;
              display: inline-block;
              color: #000;
              text-align: center;
              font-size: 12px;
              margin-left: 10px;
              position: absolute;
              top: -8px;

              span {
                position: relative;
                bottom: -2px;
              }
            }
          }

        }

        .ui.pointing.secondary.menu {
          border-bottom: 1px solid #197a56;
        }

        .ui.segment {
          border: none;
          box-shadow: none;
          padding: 0;
        }
      }
    }
  }

  .right-sec {
    background: #fff;
    // padding-left: 310px;
    width: 100%;
  }

  .sidebar {
    height: 100vh;
    z-index: 1;
    display: flex;
    position: fixed;

    .black-bar {
      background: $bcg-green;
      width: 50px;
      height: 100%;
      position: relative;
      padding: 10px 5px;

      .sidebar-ele {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        margin-bottom: 5px;
        cursor: pointer;
      }

      .active {
        background: $bcg-green--dark;
      }
    }

    .chathistory {
      display: none;
      width: 260px;
      background: #F2F2F2;
      height: 100%;
      padding: 20px 10px;

      h4 {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 20px;
        color: #323232;
      }
    }
  }

}

.two-lines-ellipsis {
  @include multilineellipsis(2);
}

.three-lines-ellipsis {
  @include multilineellipsis(3);
}