@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.all-services-wrapper {
    .all-services {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: -8px;
        margin-left: -8px;
        padding-left: 4px;
    }

    .spinner {
        margin: 0 auto;
        margin-top: 14%;
    }
}