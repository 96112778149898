$sans-reg: HendersonBCGSans-Regular, Arial, sans-serif;
$sans-bold: HendersonBCGSans-Bold, Arial, sans-serif;
$sans-light: HendersonBCGSans-Light, Arial, sans-serif;
$serif-bold: HendersonBCGSerif-Bold, Arial, serif;

:export {
  sansReg: $sans-reg;
  sansBold: $sans-bold;
  sansLight: $sans-light;
  serifBold: $serif-bold;
}