@import "src/global-styles/colors";
@import "src/global-styles/typography";

.spinnerWrapper {

    .spinner {
        position: absolute;
        left: 45%;
        top: 38%;

        .spinnerText1,
        .spinnerText2 {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            position: relative;
            left: -36%;
            margin-bottom: 0px;
            margin-left: 34px;
        }

        .spinnerText1 {
            margin-top: 20px;
        }
    }
}